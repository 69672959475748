<template>
  <div class="designer-setting-pannel">
    <div class="div-scroll">
      <div class="setting-pannel-title">基本信息</div>
      <div class="setting-pannel-content">
        <el-form
          label-position="right"
          label-width="150px"
          :model="form"
          ref="baseForm"
          :rules="rules"
        >
          <el-form-item label="头像:">
            <div class="div-avatar">
              <el-avatar
                :size="100"
                :src="form.photo || circleUrl"
                fit="cover"
              ></el-avatar>

              <el-upload
                class="upload-avatar"
                action=""
                accept="image/jpeg,image/jpg,image/png,image/bmp"
                :before-upload="beforeUpload"
                :http-request="customUpload"
                ref="imageUpload"
                :show-file-list="false"
              >
                <el-button size="small" class="plain-btn">上传头像</el-button>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="昵称:" prop="nickname">
            <el-input v-model.trim="form.nickname" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="性别:" prop="gender">
            <el-select
              v-model.trim="form.gender"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option label="男" :value="1"></el-option>
              <el-option label="女" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="出生日期:" prop="birth">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model.trim="form.birth"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              class="green-btn"
              size="medium"
              @click="submitForm"
              :disabled="isSubmit"
              >保存</el-button
            >
            <!-- <el-button @click="resetForm" size="medium" class="plain-btn"
              >取消</el-button
            > -->
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  data() {
    return {
      userInfo: {},
      SUCCESS_CODE: 0,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      form: {
        photo: "",
        nickname: "",
        gender: "",
        birth: "",
      },
      isSubmit: false,
      rules: {
        nickname: [
          {
            required: true,
            message: "请输入昵称",
            trigger: "blur",
          },
        ],
        // gender: [
        //   {
        //     required: true,
        //     message: "请选择",
        //     trigger: "change",
        //   },
        // ],
        // birth: [
        //   {
        //     required: true,
        //     message: "请选择出生日期",
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo
    this.getBaseInfo();
  },
  methods: {
    submitForm() {
      this.$refs.baseForm.validate((valid) => {
        if (valid) {
          let params = Object.assign({}, this.form);
          console.log(params)
          this.isSubmit = true;
          this.$http
            .post("/sys-user/edit", params)
            .then((res) => {
              if (res.code == this.SUCCESS_CODE) {
                this.$message.success("修改成功！");
                const info = { ...this.userInfo, ...params }
                localStorage.setItem("userInfo", JSON.stringify(info));
                localStorage.setItem("nickname", info.nickname);
              } else {
                this.$message.error("修改失败，请稍后重试！");
              }
              this.isSubmit = false;
            })
            .catch((err) => {
              this.isSubmit = false;
              this.$message.error("修改失败，请稍后重试！");
            });
        } else {
          return;
        }
      });
    },
    resetForm() {
      this.$refs.baseForm.resetFields();
    },
    // 获取个人基本信息
    getBaseInfo() {
      this.$http.get("/sys-user/load").then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          this.form.nickname = res.data.nickname;
          this.form.gender = res.data.gender;
          this.form.photo = res.data.photo;
          this.form.birth = res.data.birth.split(" ")[0];
        }
        console.log(this.form);
      });
    },
    beforeUpload(file) {
      // console.log(file);
      //   if (file.size > 51200) {
      //     this.$message.warning("您上传得图片过大，请重新上传");
      //     return false;
      //   } else {
      //     return true;
      //   }
    },
    // 自定义上传方法
    customUpload(e) {
      console.log(e);
      let self = this;
      var file = e.file;
      // this.fileList[0] = file;
      var key = uuidv4() + file.name;
      self.obsUpload(e.file, key);
    },
    obsUpload(file, key, callback) {
      let self = this;
      // self.pageLoading = true;
      // this.uploadLoading = true;
      var ak = self.$OBS_AK;
      var sk = self.$OBS_SK;
      var server = self.$OBS_SERVER;
      var bucket = self.$OBS_BUCKET;
      let prefix = self.$OBS_PREFIX;
      var obsClient = new ObsClient({
        access_key_id: ak,
        secret_access_key: sk,
        server: server,
        timeout: 60 * 5,
      });
      // var key = uuidv4() + file.name; // 生成uuid，作为唯一名称
      // put objects
      obsClient
        .putObject({
          Bucket: bucket,
          Key: key,
          Metadata: { property: "property-value" },
          SourceFile: file,
        })
        .then(function (result) {
          if (result.CommonMsg.Status == 200) {
            // callback && callback(file, key);

            // 图片地址
            let imgUrl = prefix + key;
            self.form.photo = imgUrl;
          }
        })
        .catch(function (err) {
          // self.pageLoading = false;
          // self.uploadLoading = false;
          this.$message.error(err);
          console.error(err);
        });
    },
  },
};
</script>
